import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DataSetService from 'src/services/DatasetService';
import TripsService from 'src/services/TripsService';

export const dataSetStat = createAsyncThunk(
  'dataset/dataSetStat',
  async (dataset: any) => {
    try {
      const response = await DataSetService.getDatasetStats(dataset);
      return { data: response.data };
    } catch (error) {}
  }
);

export const poiStat = createAsyncThunk('poiStat', async () => {
  try {
    const response = await TripsService.getTripsPoiStat();
    return { data: response.data };
  } catch (err) {
    console.log(err, 'poi stat');
  }
});

export const mapRoute = createAsyncThunk('mapRoute', async () => {
  try {
    const response = await TripsService.getTripsRouteMap();
    return { data: response.data };
  } catch (err) {
    console.log(err, 'map route err');
  }
});

export const mapRouteV2 = createAsyncThunk('mapRouteV2', async (data: any) => {
  try {
    const response = await TripsService.getTripsRouteMapV2(data);
    return { data: response.data };
  } catch (err) {
    console.log(err, 'map route err');
  }
});

export const filterStartPoiMapData = createAsyncThunk(
  'filterStartPoiMapData',
  async (value: string) => {
    try {
      return { data: value };
    } catch (err) {
      console.log(err, 'filter start poi map data err');
    }
  }
);
export const filterEndPoiMapData = createAsyncThunk(
  'filterEndPoiMapData',
  async (value: string) => {
    try {
      return { data: value };
    } catch (err) {
      console.log(err, 'filter end poi map data err');
    }
  }
);

export const modalityStat = createAsyncThunk('modalityStat', async () => {
  try {
    var response = await TripsService.getTripsModality();
    return { data: response.data };
  } catch (err) {
    console.log(err, 'modality err');
  }
});

export const frequenctStat = createAsyncThunk('frequencyStat', async () => {
  try {
    const response = await TripsService.getTripsFrequency();
    return { data: response.data };
  } catch (err) {
    console.log(err, 'err frequency');
  }
});

export const frequencySearchStat = createAsyncThunk(
  'frequencySearchStat',
  async (obj?: any) => {
    try {
      return { data: obj?.search, count: obj?.count, type: obj?.target };
    } catch (err) {
      console.log(err);
    }
  }
);

export const topIntends = createAsyncThunk('topIntends', async () => {
  try {
    const response = await TripsService.getIntends();
    return { data: response.data };
  } catch (err) {
    console.log(err, 'err frequency');
  }
});

const initialState = {
  loading: true,
  datasetStats: {},
  startPoi: {},
  endPoi: {},
  mapData: [],
  mapDataV2: [],
  startPoiMapData: [],
  startPoiMapDataV2: [],
  endPoiMapDataV2: [],
  endPoiMapData: [],
  modality: {},
  frequenctData10: [],
  frequenctData30: [],
  sandyCat: {},
  allFrequencyData: [],
  intends: { primary: [], intermediate: [] },
  segment: false,
  segmentData: {}
};

const homeSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(dataSetStat.fulfilled, (state, action) => {
      state.datasetStats = action.payload?.data;
    });

    builder.addCase(poiStat.fulfilled, (state, action) => {
      const data = action?.payload?.data;
      let startXaxis = [];
      let startYaxis = [];
      let endXaxis = [];
      let endYaxis = [];
      data?.start_poi?.map((item: any) => {
        if (item?._id !== '') {
          startXaxis.push(item?._id);
          startYaxis.push(item?.count);
        }
      });
      data?.end_poi?.map((item: any) => {
        if (item?._id !== '') {
          endXaxis.push(item?._id);
          endYaxis.push(item?.count);
        }
      });
      state.startPoi = {
        xAxis: startXaxis,
        yAxis: startYaxis
      };
      state.endPoi = {
        xAxis: endXaxis,
        yAxis: endYaxis
      };
      state.loading = false;
    });
    builder.addCase(mapRoute.fulfilled, (state, action) => {
      const data = action?.payload?.data?.data;
      if (data?.length > 0) {
        state.mapData = data;
        state.startPoiMapData = data?.map((item) => {
          return { sl: item?.sl, start: item?.s };
        });
        state.endPoiMapData = data?.map((item) => {
          return { el: item?.el, end: item?.e };
        });
      }
      state.loading = false;
    });
    builder.addCase(mapRouteV2.fulfilled, (state, action) => {
      const data = action?.payload?.data?.data;
      if (!!data?.length) {
        state.mapDataV2 = [...state.mapDataV2, ...data];
        state.startPoiMapDataV2 = state.mapDataV2?.map((item: any) => {
          return {
            sl: item?.a?.start_location,
            category: item?.a?.start_category,
            fs: item?.fs
          };
        });
        state.endPoiMapDataV2 = state.mapDataV2?.map((item: any) => {
          return {
            el: item?.a?.end_location,
            category: item?.a?.end_category,
            fe: item?.fe
          };
        });
      }
    });
    builder.addCase(filterStartPoiMapData.fulfilled, (state, action) => {
      const data = state.mapDataV2?.filter(
        (item) => item?.a.start_category === action.payload.data
      );
      state.startPoiMapDataV2 = data?.map((item) => {
        return {
          sl: item?.a?.start_location,
          category: item?.a?.start_category,
          fs: item?.fs
        };
      });
    });
    builder.addCase(filterEndPoiMapData.fulfilled, (state, action) => {
      const data = state.mapDataV2?.filter(
        (item) => item?.a.end_category === action.payload.data
      );
      state.endPoiMapDataV2 = data?.map((item) => {
        return {
          el: item?.a?.end_location,
          category: item?.a?.end_category,
          fe: item?.fe
        };
      });
    });
    builder.addCase(modalityStat.fulfilled, (state, action) => {
      const data = action.payload.data?.data;
      let xAxis = [];
      let yAxis = [];
      data?.map((item) => {
        xAxis.push(item?.modality?.replace('_', ' '));
        yAxis.push(item?.total);
      });
      state.modality = {
        xAxis: xAxis,
        yAxis: yAxis
      };
    });
    builder.addCase(frequenctStat.fulfilled, (state, action) => {
      const data = action.payload.data?.data;
      state.allFrequencyData = data;
      const startCat: any = [...new Set(data.map((obj: any) => obj.s))];
      const endCat = [...new Set(data.map((obj: any) => obj.e))];
      state.sandyCat = {
        startCat: startCat,
        endCat: endCat
      };
    });

    builder.addCase(frequencySearchStat.fulfilled, (state, action) => {
      const search = action.payload.data;
      const count = action.payload.count;
      const type = action.payload.type;
      const filterItem = state.allFrequencyData?.filter((item) => {
        if (type === 'origin') {
          return item.s.toLowerCase().includes(search.toLowerCase());
        }
        if (type === 'destination') {
          return item.e.toLowerCase().includes(search.toLowerCase());
        }
      });

      let stateData = [];
      const sortData = filterItem
        ?.sort(function (a: any, b: any) {
          if (b.s && a.s) {
            return b.t - a.t;
          }
        })
        .slice(0, count);

      sortData?.map((item) => {
        stateData.push({
          source: item?.s,
          target: item?.e,
          value: item?.t
        });
      });
      if (count === 30) {
        state.frequenctData30 = stateData;
      } else {
        state.frequenctData10 = stateData;
      }
    });

    builder.addCase(topIntends.fulfilled, (state, action) => {
      state.intends = action.payload.data;
    });
  }
});

const { reducer } = homeSlice;
export default reducer;
