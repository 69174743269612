import { Auth } from 'aws-amplify';
import axios from 'axios';

const TOKEN =
  'pk.eyJ1IjoibW11ZGJhcmkiLCJhIjoiY2xyNGc1czc4MW44aDJqcGV3Z2MxNDZkMiJ9.ZbJU3o_OJdAF_2jkCtlfBg';

const http = axios.create({
  baseURL: 'https://9ncwrjglv2.execute-api.us-east-2.amazonaws.com/dev/'
});

http.interceptors.request.use((config) => {
  return Auth.currentSession()
    .then((session) => {
      let accessToken = session.getIdToken();
      // console.log(accessToken['jwtToken']);
      config.headers['Authorization'] = `Bearer ${accessToken['jwtToken']}`;
      return Promise.resolve(config);
    })
    .catch(() => {
      return Promise.resolve(config);
    });
});

const getFilters = () => {
  return http.get(`/filters`);
};

const saveFilter = (filter: any) => {
  return http.post(`/filters`, filter);
};

const deleteFilter = (filter_id: string) => {
  const data = {
    filter_id: filter_id
  };

  return http.delete(`/filters`, { data });
};

const updateFilter = (filter_id: string) => {
  const data = {
    filter_id: filter_id
  };

  return http.put(`/filters`, { data });
};

const getFilterStats = (query: any) => {
  if (!query?.dataset) {
    query = { ...query, dataset: 'AUTO_USAGE_TRIPS' };
  }
  return http.post(`/trips/stats`, query);
};

const filterTripsTravelerSegment = (filterId: any) => {
  return http.post(`/trips`, {
    dataset: 'AUTO_USAGE_TRIPS',
    filter_id: filterId,
    query: []
  });
};

const filterSegmentMapRoute = (data: any) => {
  return http.post(`trips/route-data`, data);
};
const filterSegmentMapRouteV2 = (data: any) => {
  return http.post(`trips/route-dataV2`, data);
};

const filterSegmentChart = (data: any) => {
  return http.post(`segment/chart`, data);
};

const filterSegmentChartV2 = (data: any) => {
  return http.post(`SegmentChartV2`, data);
};

const filterTravelers = (params: any) => {
  return http.post(`travelers/details`, params);
};

const filterTravelersV2 = (params: any) => {
  return http.post(`TravelerDetailsV2`, params);
};

const filterModalityChart = (data: any) => {
  return http.post(`trips/segmented-modality`, data);
};

const filterModalityChartV2 = (data: any) => {
  return http.post(`SegmentModalityV2`, data);
};

const filterIntends = (params: any) => {
  return http.post(`trips/traveler-intent`, params);
};

const filterTravelerCount = (data: any) => {
  return http.post(`trips/segmented-traveler-count`, data);
};
const FilterService = {
  getFilters,
  saveFilter,
  deleteFilter,
  getFilterStats,
  filterTripsTravelerSegment,
  filterSegmentMapRoute,
  filterSegmentChart,
  filterTravelers,
  filterSegmentMapRouteV2,
  filterModalityChart,
  filterIntends,
  filterTravelerCount,
  filterModalityChartV2,
  filterSegmentChartV2,
  filterTravelersV2
};

export default FilterService;
