import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as projectsBoardReducer } from '../slices/projects_board';
import { reducer as mailboxReducer } from '../slices/mailbox';
import dashboardReducer from '../slices/dashboard';
import filterReducer from '../slices/filters';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  dashboard: dashboardReducer,
  filters: filterReducer
});

export default rootReducer;
