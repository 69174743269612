import { ButtonUnstyled } from '@mui/material';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '', errorInfo: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
    console.log(error.message);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="main-error-page">
          <div>
            <h1>There seems to be an error.</h1>
            <br />
            <ButtonUnstyled
              size="large"
              onClick={() => window.location.reload(false)}
            >
              Reload!
            </ButtonUnstyled>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
