import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';

const http = axios.create({
  baseURL: 'https://9ncwrjglv2.execute-api.us-east-2.amazonaws.com/dev/'
});

http.interceptors.request.use((config) => {
  return Auth.currentSession()
    .then((session) => {
      let accessToken = session.getIdToken();
      // console.log(accessToken['jwtToken']);
      config.headers['Authorization'] = `Bearer ${accessToken['jwtToken']}`;
      return Promise.resolve(config);
    })
    .catch(() => {
      return Promise.resolve(config);
    });
});

const getDatasetStats = (dataset: string) => {
  return http.get(`/dataset/AUTO_USAGE_TRIPS/stats`);
};

// origin and dest coordinates
const getTripsCategory = () => {
  return http.post(`/trips`, {
    query: [
      {
        $and: [
          { modality: { $ne: '' } },
          { end_category: { $ne: '' } },
          { start_category: { $ne: '' } },
          { intent: { $ne: '' } }
        ]
      }
    ],
    dataset: 'AUTO_USAGE_TRIPS'
  });
};

const modalityChartDetails = () => {
  return http.post(`/trips`, {
    query: [
      {
        $and: [
          { modality: { $ne: '' } },
          { end_category: { $ne: '' } },
          { start_category: { $ne: '' } },
          { intent: { $ne: '' } }
        ]
      }
    ],
    dataset: 'AUTO_USAGE_TRIPS'
  });
};

const originDestinationPoiChart = () => {
  return http.post(`/trips`, {
    query: [
      {
        $and: [
          { modality: { $ne: '' } },
          { end_category: { $ne: '' } },
          { start_category: { $ne: '' } },
          { intent: { $ne: '' } }
        ]
      }
    ],
    dataset: 'AUTO_USAGE_TRIPS'
  });
};

const getSearchResults = (queryData: any, skip?: number, limit?: number) => {
  const data = {
    waypoints: true,
    dataset: 'AUTO_USAGE_TRIPS',
    query: constructQuery(queryData)
  };

  if (skip) {
    data['skip'] = skip;
  }

  if (limit) {
    data['limit'] = limit;
  }

  if (queryData.selectedFilter && queryData.selectedFilter.filter_id) {
    data['filter_id'] = queryData.selectedFilter.filter_id;
  }

  return http.post(`/trips`, data);
};

const constructQuery = (data: any) => {
  var query: any[] = [];

  if (data.start && data.start.length > 0) {
    query.push({ start_time: { $gte: moment(data.start).unix() } });
  }

  if (data.end && data.end.length > 0) {
    query.push({ start_time: { $lte: moment(data.end).unix() } });
  }

  if (data.trips && data.trips.length > 0) {
    query.push({ trip_id: { $in: data.trips } });
  }

  if (data.travelers && data.travelers.length > 0) {
    query.push({ stipple_traveler_id: { $in: data.travelers } });
  }

  if (data.displacement && data.displacement.min && data.displacement.min > 0) {
    query.push({ displacement: { $gte: data.displacement.min } });
  }

  if (data.displacement && data.displacement.max && data.displacement.max > 0) {
    query.push({ displacement: { $lte: data.displacement.max } });
  }

  if (data.start_category && data.start_category.length > 0) {
    query.push({ start_category: { $in: data.start_category } });
  }

  if (data.end_category && data.end_category.length > 0) {
    query.push({ end_category: { $in: data.end_category } });
  }

  if (data.intent && data.intent.length > 0) {
    query.push({ 'intent.primary': { $in: data.intent } });
  }

  if (data.modality && data.modality.length > 0) {
    query.push({ modality: { $in: data.modality } });
  }

  if (data.waypoint_count && data.waypoint_count > 0) {
    query.push({ waypoints_count: { $gte: data.waypoint_count } });
  }

  return query;
};

const TripsService = {
  getDatasetStats,
  getSearchResults,
  getTripsCategory,
  modalityChartDetails,
  originDestinationPoiChart
  //totalTripsTravelerSegment,
  //segmentTotalDistance
};

export default TripsService;
