import moment from 'moment';


const constructQuery = (data: any) => {

    var query: any[] = [];

    if (data.start && data.start.length > 0) {
        query.push( {"start_time": {"$gte": moment(data.start).unix()}} );
    }

    if (data.end && data.end.length > 0) {
        query.push( {"start_time": {"$lte": moment(data.end).unix()}} );
    }

    if (data.displacement && data.displacement.min && data.displacement.min > 0) {
        query.push( {"displacement": {"$gte": data.displacement.min}} );
    }

    if (data.displacement && data.displacement.max && data.displacement.max > 0) {
        query.push( {"displacement": {"$lte": data.displacement.max}} );
    }

    if (data.intent && data.intent.length > 0) {
        query.push( {"intent.primary": {"$in": data.intent}} );
    }

    if (data.start_category && data.start_category.length > 0) {
        query.push( {"start_category": {"$in": data.start_category}} );
    }

    if (data.end_category && data.end_category.length > 0) {
        query.push( {"end_category": {"$in": data.end_category}} );
    }

    if (data.modality && data.modality.length > 0) {
        query.push( {"modality": {"$in": data.modality}} );
    }

    if (data.waypoint_count && data.waypoint_count > 0) {
        query.push( {"waypoints_count": {"$gte": data.waypoint_count}} );
    }

    return query;
};

const UtilFuctions = {
    constructQuery
};

export default UtilFuctions;