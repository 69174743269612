import ReactDOM from 'react-dom';
import './mocks';
import './utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from './hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { SidebarProvider } from './contexts/SidebarContext';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './contexts/AmplifyContext';
import ErrorBoundary from './utils/ErrorBoundries';
ReactDOM.render(
  <HelmetProvider>
    <ErrorBoundary>
      <Provider store={store}>
        <SidebarProvider>
          <BrowserRouter>
            <ScrollTop />
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SidebarProvider>
      </Provider>
    </ErrorBoundary>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
