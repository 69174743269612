import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from '../components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Home = Loader(lazy(() => import('../content/dashboards/Home')));
const ExploreSandy = Loader(
  lazy(() => import('../content/dashboards/ExploreSandy'))
);

const Segments = Loader(lazy(() => import('../content/dashboards/Segments')));
const SegmentDetails = Loader(
  lazy(() => import('../content/dashboards/Segments/Details'))
);
const Travelers = Loader(
  lazy(() => import('../content/dashboards/Segments/Travelers'))
);
const Explore = Loader(lazy(() => import('../content/dashboards/Explore')));
const UserStats = Loader(lazy(() => import('../content/dashboards/UserStats')));
const Compare = Loader(lazy(() => import('../content/dashboards/Compare')));
const CreateSegments = Loader(
  lazy(() => import('../content/dashboards/CreateSegments'))
);
const Organization = Loader(lazy(() => import('../content/organization')));

const dashboardsRoutes = [
  {
    path: '/',
    element: <Navigate to="home" replace />
  },
  {
    path: 'home',
    element: <Home />
  },
  {
    path: 'explore-sankey',
    element: <ExploreSandy />
  },
  {
    path: 'segments',
    element: <Segments />
  },
  {
    path: 'segments/filter',
    element: <SegmentDetails />
  },
  {
    path: 'segments/traveler',
    element: <Travelers />
  },
  {
    path: 'create-filters',
    element: <CreateSegments />
  },
  {
    path: 'explore',
    element: <Explore />
  },
  {
    path: 'compare',
    element: <Compare />
  },
  {
    path: 'travelerStats/:travellerId',
    element: <UserStats />
  },
  {
    path: 'organization',
    element: <Organization />
  }
];

export default dashboardsRoutes;
