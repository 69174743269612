import { useEffect, useState } from 'react';
import useAuth from './../../../../hooks/useAuth';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store';
import UsersService from '../../../../services/UsersService';
import { shortNumberPipe } from '../../../../utils/shortNumber';
function DatasetSelect() {
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  const { segment, segmentData, datasetStats } = useSelector(
    (state: RootState) => state.dashboard
  );
  const [datasets, setDatasets] = useState([]);
  const [dataset, setDataset] = useState('');
  const { user, logout } = useAuth();

  useEffect(() => {
    fetchAndStorageDatabase();
  }, []);

  const fetchAndStorageDatabase = () => {
    UsersService.getOrganisationDetails(user.email)
      .then((response: any) => {
        var data = response.data;
        setDatasets(data.organization[0].datasets);
        var storedDataset = localStorage.getItem('currentDatabase');
        var currentDataset = storedDataset
          ? storedDataset
          : data.organization[0].datasets[0].name;
        setDataset(currentDataset);
        localStorage.setItem('currentDatabase', currentDataset);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChange = (event: SelectChangeEvent) => {
    setDataset(event.target.value);
    localStorage.setItem('currentDatabase', event.target.value);
    window.location.reload();
  };

  return (
    <>
      <Stack
        sx={{
          flex: 1
        }}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <FormControl variant="outlined" sx={{ m: 2, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Dataset</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={`AUTO_USAGE_TRIPS`}
            onChange={handleChange}
            label="Dataset"
          >
            <MenuItem key={`AUTO_USAGE_TRIPS`} value={`AUTO_USAGE_TRIPS`}>
              AUTO_USAGE_TRIPS
            </MenuItem>
          </Select>
        </FormControl>

        <Grid sx={{ marginRight: 2, marginBottom: 1, textAlign: 'right' }}>
          <Typography
            gutterBottom
            sx={{
              fontSize: `${theme.typography.pxToRem(12)}`,
              textAlign: 'right',
              whiteSpace: 'nowrap',
              lineHeight: 1,
              textTransform: 'none'
            }}
            variant="caption"
          >
            Trips
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: `${theme.typography.pxToRem(30)}`,
              textAlign: 'right',
              whiteSpace: 'nowrap',
              lineHeight: 0.5,
              marginTop: '5px',
              color: '#223354 !important'
            }}
            variant="h2"
          >
            {segmentData['enabled']
              ? segmentData['total_trips']
                ? segmentData['total_trips']
                : '0'
              : datasetStats['total_trips']
              ? shortNumberPipe(datasetStats['total_trips'][0]?.count)
              : '12K'}
          </Typography>
        </Grid>

        <Grid sx={{ marginRight: 1, marginBottom: 1, textAlign: 'right' }}>
          <Typography
            gutterBottom
            sx={{
              fontSize: `${theme.typography.pxToRem(12)}`,
              textAlign: 'right',
              whiteSpace: 'nowrap',
              lineHeight: 1,
              textTransform: 'none'
            }}
            variant="caption"
          >
            Travelers
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: `${theme.typography.pxToRem(30)}`,
              textAlign: 'right',
              whiteSpace: 'nowrap',
              lineHeight: 0.5,
              marginTop: '5px',
              color: '#223354 !important'
            }}
            variant="h2"
          >
            {segmentData['enabled']
              ? segmentData['total_users']
                ? segmentData['total_users']
                : '0'
              : datasetStats['total_users']
              ? shortNumberPipe(datasetStats['total_users'][0]?.count)
              : '3K'}
          </Typography>
        </Grid>

        {/* <Divider orientation="vertical" flexItem /> */}
      </Stack>
    </>
  );
}

export default DatasetSelect;
