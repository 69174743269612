

export const shortNumberPipe = (number: number) => {
    if (isNaN(number)) return null;
    if (number === null) return null;
    if (number === 0) return number.toFixed(2);
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 2);
    const isNegative = number < 0;
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 }
    ];

    for (let i = 0; i < powers.length; i++) {
        let reduced = abs / powers[i].value;
        reduced = Math.round(reduced * rounder) / rounder;
        if (reduced >= 1) {
            abs = reduced;
            key = powers[i].key;
            break;
        }
    }

    let no;
    if (abs > 0 && +abs.toFixed(2) === 0) {
      no = abs.toExponential(0);
    } else {
      no = abs.toFixed(0);
    }

    return (isNegative ? '-' : '') + (no + key);
}