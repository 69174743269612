import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FilterService from 'src/services/FilterService';

export const getFilters = createAsyncThunk('dataset/filters', async () => {
  try {
    const response = await FilterService.getFilters();
    return { data: response.data };
  } catch (error) {
    return;
  }
});

export const filterGetTripsStats = createAsyncThunk(
  'dataset/filterGetTripsStats',
  async (query: any) => {
    try {
      const response = await FilterService.getFilterStats(query);
      return { data: response.data };
    } catch (error) {
      return;
    }
  }
);

export const filterTripsTravelerSegment = createAsyncThunk(
  'dataset/filterTripsTravelerSegment',
  async (filterId: any) => {
    try {
      const response = await FilterService.filterTripsTravelerSegment(filterId);
      return { data: response.data };
    } catch (error) {
      return;
    }
  }
);

export const filterSegementTripsRoute = createAsyncThunk(
  'filterSegementTripsRoute',
  async (data: any) => {
    try {
      const response = await FilterService.filterSegmentMapRoute(data);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);
export const filterSegementTripsRouteV2 = createAsyncThunk(
  'filterSegementTripsRouteV2',
  async (data: any) => {
    console.log(data);
    try {
      const response = await FilterService.filterSegmentMapRouteV2(data);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);
export const filterSegmentChart = createAsyncThunk(
  'filterSegmentChart',
  async (data: any) => {
    try {
      const response = await FilterService.filterSegmentChart(data);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);
export const filterSegmentChartV2 = createAsyncThunk(
  'filterSegmentChartV2',
  async (data: any) => {
    try {
      const response = await FilterService.filterSegmentChartV2(data);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);

export const filterTravelers = createAsyncThunk(
  'filterTravelers',
  async (id: number) => {
    try {
      const response = await FilterService.filterTravelers(id);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);
export const filterTravelersV2 = createAsyncThunk(
  'filterTravelersV2',
  async (id: any) => {
    try {
      const response = await FilterService.filterTravelersV2(id);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);

export const filterByIdsTravelers = createAsyncThunk(
  'filterByIdsTravelers',
  async (obj: any) => {
    try {
      return { data: obj?.ids, mapType: obj?.mapType };
    } catch (err) {
      return;
    }
  }
);

export const filterModalityChart = createAsyncThunk(
  'filterModalityChart',
  async (data: any) => {
    try {
      const response = await FilterService.filterModalityChart(data);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);

export const filterModalityChartV2 = createAsyncThunk(
  'filterModalityChartV2',
  async (data: any) => {
    try {
      const response = await FilterService.filterModalityChartV2(data);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);

export const filterIntends = createAsyncThunk(
  'filterIntends',
  async (id: number) => {
    try {
      const response = await FilterService.filterIntends(id);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);

export const filterTravelerCount = createAsyncThunk(
  'filterTravelerCount',
  async (data: any) => {
    try {
      const response = await FilterService.filterTravelerCount(data);
      return { data: response.data };
    } catch (err) {
      return;
    }
  }
);

const initialState = {
  loading: true,
  allFilters: [],
  filterTripsStats: {},
  filterTripsTravelerSegments: [],
  filterTripRoute: [],
  filterTripRouteV2: [],
  filterStartPoiRoute: [],
  filterEndPoiRoute: [],
  filterStartPoiRouteV2: [],
  filterEndPoiRouteV2: [],
  filterChart: {},
  filterTravelers: [],
  filterTravelersVV2: {
    trips: [],
    modality: []
  },
  filterLoading: false,
  filterUserTravelers: [],
  allFilterModalityGraph: [],
  filterModalityGraph: {},
  filterTop5Intend: [],
  filterAllSegmentChartV2: {},
  filterTravelersVVV2: [],
  filterTripsV2: []
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.allFilters = action.payload?.data;
      state.loading = false;
    });

    builder.addCase(filterGetTripsStats.fulfilled, (state, action) => {
      const data = action.payload?.data;
      var totalMiles = 0;
      if (data !== 'undefined' && data?.weekly_trip_stats?.length > 0) {
        data?.weekly_trip_stats[0]?.weekly_stats.map((trip: any) => {
          totalMiles = totalMiles + trip.total_displacement;
        });
      }

      state.filterTripsStats = {
        ...state.filterTripsStats,
        total_members: data?.total_members,
        total_trips: data?.total_trips,
        total_miles: Math.round(totalMiles / 1609.34)
      };
      state.loading = false;
    });
    builder.addCase(filterGetTripsStats.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(filterTripsTravelerSegment.fulfilled, (state, action) => {
      state.filterTripsTravelerSegments = action.payload?.data;
      state.loading = false;
    });
    builder.addCase(filterTravelers.fulfilled, (state, action) => {
      state.filterTravelers = action.payload?.data;
      state.loading = false;
    });
    builder.addCase(filterTravelersV2.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.filterTravelersVV2 = action.payload?.data;
      }
      state.loading = false;
    });
    builder.addCase(filterSegementTripsRoute.fulfilled, (state, action) => {
      const data = action.payload?.data?.data;
      state.filterLoading = true;
      if (data?.length > 0) {
        state.filterTripRoute = data;

        state.filterStartPoiRoute = data?.map((item) => {
          return { sl: item?.sl, start: item?.s };
        });
        state.filterEndPoiRoute = data?.map((item) => {
          return { el: item?.el, e: item?.e };
        });
        state.filterLoading = false;
      }
    });
    builder.addCase(filterSegementTripsRouteV2.fulfilled, (state, action) => {
      const data = action.payload?.data?.data;

      if (data?.length > 0) {
        state.filterTripRouteV2 = data;
        state.filterStartPoiRouteV2 = data?.map((item: any) => {
          return {
            sl: item?.a?.start_location,
            category: item?.a?.start_category,
            fs: item?.fs
          };
        });
        state.filterEndPoiRouteV2 = data?.map((item: any) => {
          return {
            el: item?.a?.end_location,
            category: item?.a?.end_category,
            fe: item?.fe
          };
        });
      }
    });
    builder.addCase(filterSegmentChart.fulfilled, (state, action) => {
      state.filterChart = action.payload?.data?.data[0];
    });
    builder.addCase(filterModalityChart.fulfilled, (state, action) => {
      state.filterModalityGraph = action?.payload?.data?.data;
    });
    builder.addCase(filterByIdsTravelers.fulfilled, (state, action) => {
      const data = state.filterTripRouteV2;
      const mapType = action?.payload?.mapType;
      const ids = action?.payload?.data;
      if (mapType === 'origin') {
        if (ids?.length > 0) {
          state.filterStartPoiRouteV2 = data
            ?.filter((item) => ids?.includes(item?.a?.stipple_traveler_id))
            ?.map((item) => {
              return {
                sl: item?.a?.start_location,
                category: item?.a?.start_category,
                fs: item?.fs
              };
            });
        } else {
          state.filterStartPoiRouteV2 = data?.map((item: any) => {
            return {
              sl: item?.a?.start_location,
              category: item?.a?.start_category,
              fs: item?.fs
            };
          });
        }
      }
      if (mapType === 'destination') {
        if (ids?.length > 0) {
          state.filterEndPoiRouteV2 = data
            ?.filter((item) => ids?.includes(item?.a?.stipple_traveler_id))
            ?.map((item) => {
              return {
                el: item?.a?.end_location,
                category: item?.a?.end_category,
                fe: item?.fe
              };
            });
        } else {
          state.filterEndPoiRouteV2 = data?.map((item: any) => {
            return {
              el: item?.a?.end_location,
              category: item?.a?.end_category,
              fe: item?.fe
            };
          });
        }
      }
    });

    builder.addCase(filterTravelerCount.fulfilled, (state, action) => {
      state.filterUserTravelers = action.payload?.data?.data?.slice(0, 80);
    });

    builder.addCase(filterIntends.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        state.filterTop5Intend = action.payload.data?.data;
      }
    });

    builder.addCase(filterModalityChartV2.fulfilled, (state, action) => {
      state.allFilterModalityGraph = action.payload?.data?.data;
    });

    builder.addCase(filterSegmentChartV2.fulfilled, (state, action) => {
      state.filterAllSegmentChartV2 = action?.payload?.data;
      state.filterTravelersVVV2 = action?.payload?.data?.travelers;
      state.filterTripsV2 = action?.payload?.data?.trips;
    });
  }
});

const { reducer } = filterSlice;
export default reducer;
