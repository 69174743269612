import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import Util from './common';

const http = axios.create({
  baseURL: 'https://9ncwrjglv2.execute-api.us-east-2.amazonaws.com/dev/'
});

http.interceptors.request.use((config) => {
  return Auth.currentSession()
    .then((session) => {
      let accessToken = session.getIdToken();
      // console.log(accessToken['jwtToken']);
      config.headers['Authorization'] = `Bearer ${accessToken['jwtToken']}`;
      return Promise.resolve(config);
    })
    .catch(() => {
      return Promise.resolve(config);
    });
});

const getUserStats = (user_id: string, queryData: any) => {
  var data = {
    query: Util.constructQuery(queryData),
    dataset: 'AUTO_USAGE_TRIPS'
  };

  return http.post(`/travelers/${user_id}/stats`, data);
};

const getUserTrips = (
  user_id: string,
  polygon: any[],
  queryData: any,
  skip?: number,
  limit?: number
) => {
  var data = {
    query: Util.constructQuery(queryData),
    dataset: 'AUTO_USAGE_TRIPS'
  };

  if (polygon && polygon.length > 0) {
    data['polygon'] = polygon;
  }

  if (skip) {
    data['skip'] = skip;
  }

  if (limit) {
    data['limit'] = limit;
  }

  if (queryData.selectedFilter && queryData.selectedFilter.filter_id) {
    data['filter_id'] = queryData.selectedFilter.filter_id;
  }

  return http.post(`/travelers/${user_id}/trips`, data);
};

const getOrganisationDetails = (email: string) => {
  var data = {
    email: email
  };

  return http.post(`/organization`, data);
};

const UsersService = {
  getUserStats,
  getUserTrips,
  getOrganisationDetails
};

export default UsersService;
