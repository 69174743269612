import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';

const TOKEN =
  'pk.eyJ1IjoibW11ZGJhcmkiLCJhIjoiY2xyNGc1czc4MW44aDJqcGV3Z2MxNDZkMiJ9.ZbJU3o_OJdAF_2jkCtlfBg';

const http = axios.create({
  baseURL: 'https://9ncwrjglv2.execute-api.us-east-2.amazonaws.com/dev/'
});

http.interceptors.request.use((config) => {
  return Auth.currentSession()
    .then((session) => {
      let accessToken = session.getIdToken();
      // console.log(accessToken['jwtToken']);
      config.headers['Authorization'] = `Bearer ${accessToken['jwtToken']}`;
      return Promise.resolve(config);
    })
    .catch(() => {
      return Promise.resolve(config);
    });
});

const getTripDetails = (trip_ids: string[]) => {
  const data = {
    trips_list: trip_ids,
    waypoints: true,
    dataset: 'AUTO_USAGE_TRIPS'
  };

  return http.post(`/trips`, data);
};

const getMapboxRoutes = (coordinates) => {
  return http.get(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=${TOKEN}`
  );
};

const getTripsPoiStat = () => {
  return http.post(`/trips/poi-stats`);
};

const getTripsRouteMap = () => {
  return http.post(`trips/route-data`);
};

const getTripsRouteMapV2 = (data: any) => {
  return http.post(`trips/route-dataV2`, data);
};

const getTripsModality = () => {
  return http.post('/trips/modality-distribution');
};

const getTripsFrequency = () => {
  return http.post('trips/freq-distribution');
};

const getIntends = () => {
  return http.get('intent');
};

const TripsService = {
  getTripDetails,
  getMapboxRoutes,
  getTripsPoiStat,
  getTripsRouteMap,
  getTripsModality,
  getTripsFrequency,
  getIntends,
  getTripsRouteMapV2
};

export default TripsService;
