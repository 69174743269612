import type { ReactNode } from 'react';

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import ChangeHistoryTwoTone from '@mui/icons-material/ChangeHistoryTwoTone';
import PermIdentityTwoToneIcon from '@mui/icons-material/PermIdentityTwoTone';
import BubbleChartTwoToneIcon from '@mui/icons-material/BubbleChartTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import StackedLineChartTwoToneIcon from '@mui/icons-material/StackedLineChartTwoTone';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Foundation',
    items: [
      {
        name:'Dashboard',
        link:'/dashboard/home',
        icon: HomeTwoToneIcon,
      },
      {
        name: 'Segments',
        link: '/dashboard/segments',
        icon: BubbleChartTwoToneIcon,
      },
      {
        name: 'Explore',
        link: '/dashboard/explore',
        icon: TravelExploreTwoToneIcon,
      },
      {
        name: 'Compare',
        link: '/dashboard/compare',
        icon: StackedLineChartTwoToneIcon,
      },
      // {
      //   name: 'Create Filters and Fences',
      //   link: '/dashboard/create-filters',
      //   icon: FilterAltTwoToneIcon,
      // },
    ]
  }
];

export default menuItems;
